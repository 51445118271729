import React, { useEffect, useState } from 'react';
import { Box, Button, RadioGroup, FormControlLabel, Radio, TextField, Typography, IconButton, Toolbar, Divider, Select, MenuItem } from '@mui/material';
import { api_url } from "../../../config/api";
import { getAccessToken } from '../../../utils/auth';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import './Sidebar.css'
import EmiIntroModal from './EmiIntroModal';

interface SidebarProps {
    sessionId: string | null;
    version: string | null;
    handleDrawerToggle: () => void;
    startNewSession: () => void;
    handleSignOut: () => void;
    selectedModel: string;
    handleSetSelectedModel: (event: any) => void;

}

const Sidebar: React.FC<SidebarProps> = ({ sessionId, version, handleDrawerToggle, startNewSession, handleSignOut, selectedModel, handleSetSelectedModel }: SidebarProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modelOptions, setModelOptions] = useState<any[]>([]);

    useEffect(() => {
        const fetchModels = async () => {
            fetch(`${api_url}/models`, {
                headers: {
                    Authorization: `Bearer ${await getAccessToken()}`,
                }
            } )
                .then(res => res.json())
                .then(data => setModelOptions(data.models))
                .catch(err => console.error(err));
        }
        fetchModels();
    }, []);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <Box p={2}>
            <Toolbar>
                <IconButton onClick={handleDrawerToggle}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <Box p={2}>
                <Typography variant="h6" noWrap component="div">
                    Emi API Version: {version}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={startNewSession}
                    fullWidth
                >
                    Start New Session
                </Button>
                {sessionId && (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                        style={{ marginTop: "8px" }}
                    >
                        Session ID: {sessionId}
                    </Typography>
                )}
                <Select
                    fullWidth
                    value={selectedModel}
                    onChange={(e) => handleSetSelectedModel(e)}
                >
                    {modelOptions && modelOptions.map((model) => (
                        <MenuItem key={model} value={model}>
                            {model}
                        </MenuItem>
                    ))}
                </Select>
                <Button
                    className="get-started-button"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleOpenModal}
                >
                    Getting Started? Click Here
                </Button>
                <Button
                    className="mobile-sign-out-button"
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={handleSignOut}
                >
                    Sign Out
                </Button>
            </Box>
            <EmiIntroModal open={isModalOpen} handleClose={handleCloseModal} />
        </Box>
    );
};

export default Sidebar;

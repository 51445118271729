import React, { useState } from "react";
import {
  Typography,
  Divider,
  ListItem,
  IconButton,
  Box,
  Button,
  Link,
  Grid,
  Chip
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/StopCircleOutlined";
import { Message } from "./Message";
import { api_url } from "../../../config/api";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './MessageBubble.css';
import { getAccessToken } from "../../../utils/auth";
import MessageActions from "./MessageActions";

let audio: HTMLAudioElement | null = null;
let abortController: AbortController | null = null;

const playMessage = async (
  messageId: string,
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
) => {

  setIsPlaying(true);
  abortController = new AbortController(); // Create a new AbortController for each play

  try {
    const accessToken = await getAccessToken();
    const response = await fetch(`${api_url}/get_audio?message_id=${messageId}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
      signal: abortController.signal, // Attach the signal to the fetch
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    if (!response.body) {
      throw new Error("Response body is null");
    }

    // Create a MediaSource object for streaming
    const mediaSource = new MediaSource();
    audio = new Audio();
    audio.src = URL.createObjectURL(mediaSource);

    let isStopped = false; // Flag to check if the playback was stopped

    mediaSource.addEventListener("sourceopen", () => {
      if (!mediaSource || isStopped) return;

      const sourceBuffer = mediaSource.addSourceBuffer("audio/mpeg");
      const reader = response.body!.getReader();

      const readChunk = async () => {
        const { done, value } = await reader.read();
        if (done || isStopped) {
          if (!isStopped) {
            mediaSource.endOfStream();
          }
          return;
        }

        // Wait for the SourceBuffer to be ready before appending the next chunk
        sourceBuffer.appendBuffer(value);
        sourceBuffer.addEventListener("updateend", readChunk, { once: true });
      };

      readChunk(); // Start reading and appending chunks
    });

    audio.play();

    audio.onended = () => {
      setIsPlaying(false);
      audio = null;
    };

    // Handle manual stop
    audio.onpause = () => {
      if (isStopped) {
        setIsPlaying(false);
        audio = null;
      }
    };

  } catch (error: any) {
    if (error.name !== "AbortError") {
      console.error("Error playing message:", error);
    }
    setIsPlaying(false);
  }
};

const stopMessage = (
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (audio) {
    audio.pause();
    audio = null;
  }

  // Abort ongoing fetch
  if (abortController) {
    abortController.abort();
    abortController = null;
  }

  setIsPlaying(false);
};

interface MessageBubbleProps {
  msg: Message;
  messageRetrievalHandler: (messageId: string) => void;
}

// Modified ListItemText to include conditional styling and play button
const MessageBubble = ({ msg, messageRetrievalHandler }: MessageBubbleProps) => {

  const [isPlaying, setIsPlaying] = useState(false);
  const isUserMessage = msg.sender === "User"; // Adjust condition based on your data structure
  const bubbleClass = isUserMessage ? 'bubble user' : 'bubble ai';

  const togglePlayMessage = (message: Message) => {
    if (isPlaying) {
      stopMessage(setIsPlaying);
    } else {
      playMessage(message.id!, setIsPlaying);
    }
  };

  const formatText = (text: string) => {
    return text.replace(/(?:\r\n|\r|\n)/g, "<br/>");
  }

  return (
    <ListItem className="bubble-list-item">
      <div className={bubbleClass}>
        {msg.sender === "User" && (
          <Typography
            component="span"
            variant="body2"
            dangerouslySetInnerHTML={{ __html: formatText(msg.text) }}
          >
          </Typography>
        )}
        {msg.sender === "AI" && (
          <>
            <img
              src="/EmiLogoNotTransparent.svg"
              alt="Emi Icon"
              className="emi-icon"
            />
            <div style={{ display: 'grid', gap: '16px' }}>
              <Typography
                component="span"
                variant="body2"
                dangerouslySetInnerHTML={{ __html: formatText(msg.text) }}
              >
              </Typography>
            </div>
            {msg.tags && <div className='ai-tags'>
              {msg.tags.map((tag) => (
                <Chip key={tag} label={tag} />
              ))}
            </div>}
            <MessageActions
              msg={msg}
              isPlaying={isPlaying}
              togglePlayMessage={togglePlayMessage}
            />
            {msg.tags && (
              <></>
              // <Box mt={1}>
              //   <Carousel
              //     responsive={responsive}
              //     infinite={false}
              //     autoPlay={false}
              //     arrows
              //   // showDots={true}
              //   >
              //     {msg.tags.map((tag, index) => {
              //       const tagLink = tag.replace(/\s+/g, '-').toLowerCase();
              //       const mockImage1 = '/mock-images/mock1.jpg';
              //       const mockImage2 = '/mock-images/mock2.jpg';
              //       const mockImage = index % 2 === 0 ? mockImage1 : mockImage2;
              //       return (
              //         <div>
              //           <Box key={index} className="tag-tile">
              //             <Link href={`https://www.gottman.com/blog/category/${tagLink}`} target='_blank' underline="none">
              //               <img src={mockImage} alt={tag} className="tag-image" />
              //               <Typography variant="body2" className="tag-title">
              //                 {tag}
              //               </Typography>
              //             </Link>
              //           </Box></div>)
              //     })}
              //   </Carousel>
              // </Box>
            )}
          </>
        )}
      </div>
      <Divider />
    </ListItem>
  );
};

export default MessageBubble;

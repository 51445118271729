import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Chip,
  Grid,
  Snackbar,
  SnackbarOrigin,
} from '@mui/material';
import {
  PlayArrow as PlayArrowIcon,
  Stop as StopIcon,
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Message } from './Message';
import './MessageActions.css';
import { getAccessToken } from '../../../utils/auth';
import axios from 'axios';
import { api_url } from '../../../config/api';
import handleSubmitFeedback from '../../../utils/feedback';

const MessageActions = ({
  msg,
  isPlaying,
  togglePlayMessage,
}: {
  msg: Message;
  isPlaying: boolean;
  togglePlayMessage: (msg: Message) => void;
}) => {

  // Needed for Snackbar Notification
  interface ISnackBar extends SnackbarOrigin {
    snackBarOpen: boolean;
    snackBarMessage: string;
  }

  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackDescription, setFeedbackDescription] = useState('');

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [allTags, setAllTags] = useState<string[]>([]);

  const [snackBarState, setSnackBarState] = useState<ISnackBar>({
    snackBarOpen: false,
    snackBarMessage: '',
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, snackBarOpen, snackBarMessage } = snackBarState;

  const handleFeedbackModalOpen = () => setFeedbackModalOpen(true);
  const handleFeedbackModalClose = () => setFeedbackModalOpen(false);

  useEffect(() => {
    setSelectedTags(msg.tags || []);
  }, [msg]);

  const handleFeedbackDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedbackDescription(event.target.value);
  };

  const handleSubmitFeedbackWrapper = async (rating: number) => {
    if (!msg.id) {
      setSnackBarState({ snackBarOpen: true, snackBarMessage: 'Failed to submit feedback: Message ID is missing', vertical: 'top', horizontal: 'center' });
      return;
    }
    
    const success = await handleSubmitFeedback(rating, msg.id, feedbackDescription, selectedTags);
    if (success) {
      setSnackBarState({ snackBarOpen: true, snackBarMessage: 'Feedback submitted', vertical, horizontal });
    } else {
      setSnackBarState({ snackBarOpen: true, snackBarMessage: 'Failed to submit feedback', vertical, horizontal });
    }
    setFeedbackModalOpen(false);
  };

  const handlePositiveFeedback = () => {
    // Handle positive feedback logic here
    handleSubmitFeedbackWrapper(1);
  };

  const handleNegativeFeedback = () => {
    // Handle negative feedback logic here
    handleSubmitFeedbackWrapper(0);
  }

  const handleSnackbarClose = () => {
    setSnackBarState({ ...snackBarState, snackBarOpen: false });
  };

  return (
    <>
      <div className="message-actions">
        <IconButton onClick={() => togglePlayMessage(msg)} size="small">
          {isPlaying ? <StopIcon /> : <PlayArrowIcon />}
        </IconButton>
        <IconButton size="small" onClick={handlePositiveFeedback}>
          <ThumbUpIcon />
        </IconButton>
        <IconButton size="small" onClick={handleFeedbackModalOpen}>
          <ThumbDownIcon />
        </IconButton>
        <Modal open={feedbackModalOpen} onClose={handleFeedbackModalClose}>
          <Box className="feedback-modal">
            {/* Close Button */}
            <IconButton
              aria-label="close"
              onClick={handleFeedbackModalClose}
              className="modal-close-button"
            >
              <CloseIcon />
            </IconButton>

            <Typography variant="h6" className="modal-title">Provide Feedback</Typography>
            <TextField
              label="Feedback"
              multiline
              rows={6}
              value={feedbackDescription}
              onChange={handleFeedbackDescriptionChange}
              variant="outlined"
              fullWidth
              style={{ marginBottom: '10px' }}
              className="modal-textfield"
            />
            {/* <Typography variant="body1" className="modal-description" style={{ marginBottom: '10px' }}>
              Emi has assigned your inquiry the tags highlighted in blue. If any tags seem incorrect, you can deselect them. Additionally, feel free to select any grayed-out topics that apply. Your feedback helps us make Emi even smarter!
            </Typography>
            <Grid container spacing={1} className="modal-tag-grid">
              {allTags.map((tag) => (
                <Grid item key={tag}>
                  <Chip
                    label={tag}
                    onClick={() => handleTagClick(tag)}
                    color={selectedTags.includes(tag) ? 'primary' : 'default'}
                    clickable
                    style={{ cursor: 'pointer' }}
                  />
                </Grid>
              ))}
            </Grid> */}
            <Button
              onClick={() => handleNegativeFeedback()}
              color="primary"
              variant="contained"
              className="modal-submit-button"
              style={{ marginTop: '10px' }}
            >
              Submit
            </Button>
          </Box>
        </Modal>
      </div>
      <Box sx={{ width: 500 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackBarOpen}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
          message={snackBarMessage}
          key={vertical + horizontal}
        />
      </Box>
    </>
  );
};

export default MessageActions;

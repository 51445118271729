import React from 'react';
import { Box, Typography, Modal } from '@mui/material';
import './LoadingPlaceholder.css';
import BlobVisualizer from './BlobVisualizer';

interface LoadingPlaceholderProps {
    open: boolean;
}

const LoadingPlaceholder: React.FC<LoadingPlaceholderProps> = ({ open }) => {
    return (
        <Modal open={open} className="loading-modal">
            <Box className="loading-placeholder">
                <Typography variant="h4" className="loading-text">
                    Thinking...
                </Typography>
                <Box className="loading-visual">
                    <BlobVisualizer />
                </Box>
            </Box>
        </Modal>
    );
};

export default LoadingPlaceholder;
import axios from 'axios';
import { getAccessToken } from "./auth";
import { api_url } from "../config/api";

const handleSubmitFeedback = async (rating: number, message_id: string, description?: string, tags?: string[]) => {
    const accessToken = await getAccessToken();
    const headers = {
        Authorization: `Bearer ${accessToken}`,
    };

    // create string from tags array
    const tagsString = tags ? tags.join(',') : '';

    const feedbackData = {
        rating,
        description,
        message_id,
        tags: tagsString
    };

    const response = await axios.post(`${api_url}/save_feedback`, feedbackData, { headers });
    if (response.status === 200) {
        return true;
    } else {
        return false;
    }
};

export default handleSubmitFeedback;
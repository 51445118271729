import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
} from "@mui/material";
import "./EmiIntroModal.css"; // Include the CSS for the background and styles

const EmiIntroModal = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="emi-intro-modal">
        <Box 
          className="emi-picture" 
          sx={{
            background: 'url(/EmiLogoNotTransparent.svg) no-repeat center center',
            backgroundSize: 'contain',
          }}
        />
        <Typography variant="h4" className="emi-title" gutterBottom>
          Meet Emi
        </Typography>
        <Typography variant="body1" className="emi-description">
          Emi is your advanced AI for relationships, trained on the world-renowned Gottman Method.
        </Typography>
        <Typography variant="h6" className="emi-subtitle" gutterBottom>
          What can you do with Emi?
        </Typography>
        <ul className="emi-list">
          <li>Ask for reading recommendations.</li>
          <li>Ask your most pressing relationship questions.</li>
          <li>Work through Gottman's famous softened startup exercise (say, "Hey, I'd like to do an exercise").</li>
          <li>Chat with an empathetic companion.</li>
          <li>Ask for date night ideas.</li>
        </ul>
        <Button
          variant="contained"
          color="primary"
          className="emi-close-button"
          onClick={handleClose}
        >
          Let's Get Started
        </Button>
      </Box>
    </Modal>
  );
};

export default EmiIntroModal;
